import {
  LazyLoadingHandler_default
} from "./chunk.YPEUFH4Z.js";
import {
  Spaceable
} from "./chunk.5AVD7Z5R.js";
import {
  customElement
} from "./chunk.7SN7OVTI.js";
import {
  s,
  x
} from "./chunk.55ES4XIJ.js";
import {
  e,
  n
} from "./chunk.6SUS7CJL.js";
import {
  __decorateClass
} from "./chunk.SO6RGV6L.js";

// src/components/jb-interactive-animation/jb-interactive-animation.ts
var JbInteractiveAnimation = class extends Spaceable(s) {
  firstUpdated() {
    LazyLoadingHandler_default.observe(
      this,
      async () => {
        await import("./lottie-player.esm.OP6KQVC5.js");
      },
      "approachingViewport"
    );
    this._lottiePlayer?.addEventListener("play", () => {
      this.links?.forEach((link) => {
        this._lottiePlayer?.shadowRoot?.querySelectorAll(link.selector).forEach((element) => {
          element.style.cursor = "pointer";
          element.addEventListener("click", () => {
            window.location.href = link.href;
          });
        });
      });
    });
  }
  render() {
    return x`
      <lottie-player
        autoplay
        loop
        mode="normal"
        src="${this.animationFile}"
      ></lottie-player>
    `;
  }
};
__decorateClass([
  n()
], JbInteractiveAnimation.prototype, "animationFile", 2);
__decorateClass([
  n({ type: Array })
], JbInteractiveAnimation.prototype, "links", 2);
__decorateClass([
  e("lottie-player")
], JbInteractiveAnimation.prototype, "_lottiePlayer", 2);
JbInteractiveAnimation = __decorateClass([
  customElement("jb-interactive-animation")
], JbInteractiveAnimation);

export {
  JbInteractiveAnimation
};
